<template>
	<div id="landList">
		<page-add-work-service :is-shop="1"></page-add-work-service>
	</div>
</template>
<script>
	import PageAddWorkService from '@/components/layout/land/page-add-work-service.vue'
	export default{
		components:{
			PageAddWorkService
		},
	}
</script>

<style>
</style>
